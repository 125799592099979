import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { StepComponentProps, SingleChoiceQuizStep } from "../../../@types";
import Actions from "@components/quiz/actions";
import { mapSelectOptions } from '../../SelectOption'
type Props = StepComponentProps<SingleChoiceQuizStep, string>

export default function SingleChoice(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props;
  const nextEnabled = useMemo(() => value !== '', [value]);

  const handleInputChange = (value: string) => {
    console.log("VALUE", value)
    handleNext(value);
  }

  const handleNext = (newValue: string) => {
    onChange(step.id, newValue);
  }

  const options = step.options.map((option) => ({
    value: option.value,
    content: option.name,
    selected: value == option.value,
    onClick: handleInputChange,
  }))

  return (
    <>
      <div className="flex flex-wrap min-w-full max-w-2xl -mx-2 items-center justify-center">
        {mapSelectOptions({
          height: 'h-30',
          width: 'w-full',
          spacing: 'm-2',
          options,
        })}
      </div>
      {/* <Actions
        onNext={() => handleNext(value)}
        nextEnabled={nextEnabled}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      /> */}
    </>
  )
}
