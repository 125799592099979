import Script from "next/script";

const Criteo = ({ setLoadedScript }: any) => (
  <>
    <Script src={`//dynamic.criteo.com/js/ld/ld.js?a=${process.env.NEXT_PUBLIC_CRITEO_ID}`} onLoad={() => { setLoadedScript('criteo') }} />
  </>
)

export const criteoHomepageEvent = () => (<><Script id="criteo-homepage">
  {`window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent) ? "t" :
  /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
  window.criteo_q.push(
  { event: "setAccount", account: ${process.env.NEXT_PUBLIC_CRITEO_ID} },
  { event: "setSiteType", type: deviceType },
  { event: "viewHome", tms: "custom-guide" });`}</Script></>)

export const criteoTransactionEvent = (transaction: any) => (<><Script id="criteo-transaction">
  {`window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent) ? "t" :
  /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
  window.criteo_q.push(
  { event: "setAccount", account: ${process.env.NEXT_PUBLIC_CRITEO_ID} },
  { event: "setSiteType", type: deviceType },
  { event: "trackTransaction", tms: "custom-guide", id: "${transaction.id}", item: ${JSON.stringify(transaction.items)} });`}</Script></>)

export default Criteo;
