// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://d7fd2c9e80114b22b311c65323e05666@o1253834.ingest.sentry.io/6424399',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  ignoreErrors: ['this.trackNode.getClientRect'],
  environment: process.env.VERCEL_ENV,
  enabled: ['preview', 'production'].includes(process.env.VERCEL_ENV),
  release: process.env.NEXT_PUBLIC_VERSION,
})
