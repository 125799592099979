import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectProgressPercent } from "redux/quiz/steps";

interface ProgressProps {
  showProgress?: boolean;
}
const Progress: FunctionComponent<ProgressProps> = ({ showProgress }) => {
  const progress = useSelector(selectProgressPercent)

  return (
    <div className="progress-bar w-full bg-gray-200 rounded-lg mx-auto">
      <div className="rounded-full" style={{ width: `${progress}%` }}></div>
    </div>
  )
}
export default Progress
