import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StripeIntentResponse } from "@types";
import Stripe from "stripe";
import { RootState } from "../store";

// Define a type for the slice state
type StripeState = {
  intent: Stripe.PaymentIntent | null;
};

// Define the initial state using that type
const initialState: StripeState = {
  intent: null,
};

export const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    setStripeIntent: (state, action: PayloadAction<Stripe.PaymentIntent>) => {
      state.intent = action.payload;
    },
    clearStripeIntent: (state) => {
      state.intent = null;
    },
  },
});

export const selectStripeIntent = (
  state: RootState
): Stripe.PaymentIntent | null => (state.stripe as StripeState)?.intent;

export const { setStripeIntent, clearStripeIntent } = stripeSlice.actions;

export default stripeSlice.reducer;
