import React, { useEffect, useState } from "react";
import { StepComponentProps, SelectQuizStep } from "@types";
import Actions from "@components/quiz/actions";
type Props = StepComponentProps<SelectQuizStep, string>

const Select: React.FunctionComponent<Props> = (props) => {
  const { value, step, onChange } = props;
  const [result, setResult] = useState(value)

  useEffect(() => {
    setResult(value)
  }, [value, setResult])

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setResult(event.target.value);
  }

  const handleNext = () => {
    onChange(step.id, result);
  }

  return (
    <>
      <div className="flex flex-wrap min-w-full max-w-2xl -mx-2 items-center justify-center">
        <select value={result} onChange={changeHandler}>
          <option disabled={result !== ''}>Select a state</option>
          {step.options.map((option, idx) => <option key={idx} value={option.value}>{option.name}</option>)}
        </select>
      </div>
      <Actions
        onNext={handleNext}
        nextEnabled={result !== ''}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </>
  )
}
export default Select;
