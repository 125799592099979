import Stripe from 'stripe'

export type Nullable<T> = T | null

export interface QuizProps {
  step: QuizSteps
  previousStep?: number
  currentStep: number
  nextStep?: number | null
  isLastStep: boolean
  stepsCount: number
}

export enum StepId {
  GENDER = 'gender',
  USER_GOALS = 'user_goals',
  NAME = 'name',
  CONTENT_INTRO = 'content_intro',
  STATE = 'state',
  CONDITIONS = 'conditions',
  HEIGHT_WEIGHT = 'height_weight',
  CURRENT_WEIGHT = 'current_weight',
  WEIGHT_LOSS = 'weight_loss',
  BIRTHDAY = 'birthday',
  RACE = 'race',
  HEALTH_CONDITIONS = 'health_conditions',
  CONTENT_BENEFITS = 'content_benefits',
  LAST_IDEAL = 'last_ideal',
  PREFERRED_RATE = 'preferred_rate',
  CONTENT_SET_POINT = 'content_set_point',
  ACTIVITY_LEVEL = 'activity_level',
  TYPICAL_DAY = 'typical_day',
  HABITS = 'habits',
  OTHER_DIETS = 'other_diets',
  CONTENT_PERSONALIZED = 'content_personalized',
  STRUGGLE = 'struggle',
  NUM_MEALS = 'num_meals',
  WATER = 'water',
  FOODS = 'foods',
  VEGETABLES = 'vegetables',
  MEATS = 'meats',
  FRUITS = 'fruits',
  ALLERGIES = 'allergies',
}

export type QuizStepType_delete_me =
  | 'birthday'
  | 'single-choice'
  | 'select'
  | 'text'
  | 'number'
  | 'multiple-choice'
  | 'height-weight'
  | 'slider'
  | 'content'

export enum QuizStepType {
  BIRTHDAY = 'birthday',
  SINGLE_CHOICE = 'single-choice',
  SELECT = 'select',
  TEXT = 'text',
  NUMBER = 'number',
  MULTIPLE_CHOICE = 'multiple-choice',
  HEIGHT_WEIGHT = 'height-weight',
  SLIDER = 'slider',
  CONTENT = 'content',
}

export type QuizStepOption = {
  name: string
  value: string
  icon?: string
  showOther?: boolean
}

interface OptionQuizStep {
  name: string
  value: string | number
}

export type QuizStepBase = {
  id: StepId
  type: QuizStepType
  label: string
  hint?: string
  pretext?: string
  options?: OptionQuizStep[]
  _comment?: string
}

export type QuizSteps =
  | QuizStepBase
  | SingleChoiceQuizStep
  | SliderQuizStep
  | SelectQuizStep
  | TextQuizStep
  | NumberQuizStep
  | MultipleChoiceQuizStep
  | HeightWeightQuizStep
  | ContentQuizStep
  | BirthdayStep

export interface SingleChoiceQuizStep extends QuizStepBase {
  options: QuizStepOption[]
}

export interface SliderQuizStep extends QuizStepBase {
  min?: number
  maxSelector: string // name of user selector defined in `redux/user/`
}

export interface SelectQuizStep extends QuizStepBase {
  options: QuizStepOption[]
}

export interface TextQuizStep extends QuizStepBase {
  placeholder: string
  units?: string
}

export type Units = 'metric' | 'imperial'

export interface NumberQuizStep extends QuizStepBase {
  placeholder: string
  units?: Units
  min: number
  max: number
  pattern: string
}

export interface MultipleChoiceQuizStep extends QuizStepBase {
  columns: boolean
  options: QuizStepOption[]
  compact: boolean
  negation: boolean
}

export type StepChangeHandler<V> = (name: string, value: V) => void

export type StepComponentProps<T, V> = {
  step: T
  onChange: StepChangeHandler<V>
  value: V
  previousStep?: number
  nextStep?: number | null
  isLastStep: boolean
}

export interface HeightWeightQuizStep extends QuizStepBase {
  showHeight: boolean
  showWeight: boolean
  weightId: string
}

export interface ContentQuizStep extends QuizStepBase {
  content?: string
  image: string
  image_alt: string
}

export interface BirthdayStep extends QuizStepBase {
  month: number
  year: number
  day: number
}

export interface Plan {
  id: string
  duration: number
  amount: number
  originalPrice: number
  salePrice: number
  name: string
  line_item: string
  billed: 'monthly' | 'quarterly' | 'annually' | 'biannual'
  stripe_product_id: string
  stripe_price_id: string
  deal: string | null
  billed_long: string
  sku: string
  productName: string
  productNameLong: string
  showMarkdownPrice: boolean
  showSavedPercent: boolean
  bonus: string | null
  showBonus: boolean | null
  showSpecialOffer: string | null
  offers: Array<string> | null
  track: Track
  valueText?: string
  valueAmount?: number
  couponApplied?: boolean
}

interface Discount {
  name: string
  amount: number
  value: number
}

export type HealthGoal =
  | 'lose_weight'
  | 'build_muscle'
  | 'healthy_habits'
  | 'lose_20_to_50'
  | 'lose_50_to_100'
  | 'lose_100_plus'
  | 'lower_a1c'

export interface NutritionMacro {
  min: number
  max: number
  avg: number
}

export interface Macros {
  bmi: number
  height: number
  weight: number
  activity_level?: string
  health_goal: HealthGoal
  gender: string
  age: number
  water: number
  calories: NutritionMacro
  carbs: NutritionMacro
  proteins: number
  fats: number
  minCalories: number
  maxCalories: number
  avgCalories: number
}

export type Gender = 'female' | 'male' | 'other' | 'na'

export type Name = string

export interface StripeCheckoutFormUserInfo {
  name: string
  email: string
  phone: string
}
export interface PaymentProcessorData {
  customerId: string
  paymentId: string
  paymentMethodId: string
  subscriptionId: string
  totalPaid: number
  referrer: string
  selectedPlan: Plan
  purchased: Plan[]
  leadId: string
  name: string
  email: string
  phone: string
  paymentEmail: string
  funnel?: string
  flags: [string]
}

export type Processor = 'stripe'

export interface PurchaseOrder {
  email: string
  phone: string
  leadId: string
  paymentId: string
  totalPaid: number
  referrer: string
  subscriptionId: string
  customerId: string
  selectedPlan: Plan
  discounts: Discount[]
  processor: Processor
  name: string
  paymentProcessorData?: PaymentProcessorData // FIXME: this will become compulsory when we unify Stripe/Paypal purchase paths
  location: string
}

export interface Purchased extends Plan {
  name: string
  price: number
  variant: string
  quantity: number
  id: string
}

interface CheckoutResponse {
  paymentId: string
  paymentIntent: string
  paymentEmail: string
  paymentMethodId: string
  subscriptionId: string
  name: string
  phone: string
  email: string
  amount: number
  purchased: Plan[]
  customerId: string
}

export type BraintreeCheckoutReponse = CheckoutResponse
export interface StripeIntentCreateParams {
  amount: number
}
export interface StripeIntentUpdateParams {
  id: string
  amount: number
}

export type StripeCoupon = {
  id: string
  amount_off: number
  created: number
  currency: string
  duration: string
  duration_in_months: number
  livemode: boolean
  max_redemptions: number
  metadata: {
    [key: string]: string
  }
  name: string
  percent_off: number
  redeem_by: number
  times_redeemed: number
  valid: boolean
  code?: string
}

export type PromoCode = {
  code: string
  offer: string
  description: string
  amount: number
  oneTime: boolean
}

export type StripeIntentResponse =
  | {
      id: string
      status: true
      stripeIntent: Stripe.PaymentIntent
      clientSecret: string
    }
  | {
      status: false
      message: string
    }

export interface StripeCheckoutResponse extends CheckoutResponse {
  stripeResponse: Stripe.Response<Stripe.Subscription>
}

export interface UserCreationRequest extends PaymentProcessorData {
  processor: Processor
}

export enum EligiblityReason {
  AGE,
  CONDITION,
}

export enum Track {
  BEHAVIORAL = 'behavioral',
  MEDICAL = 'medical',
}

// This is the response body of TEMP_APP_ROOT_URL/api/v1/results/:id
export interface ResultsResponse {
  leadId: number
  slug: string
  macros: Macros
  email: string
  currentWeight: number
  targetWeight: number
  gender: string
  track: string
  progressAmount: number
  quizAnswers: any
}

export interface CareTeamMember {
  id: string
  first_name: string
  last_name: string
  display_name: string
  member_type: string
  email: string
  scheduling_link: string
  bio: string
  picture: string
}

// This is the response body of /api/purchase
export type PurchaseResponse = {
  data: {
    id: string
    type: 'User'
    attributes: {
      careTeamMembers: CareTeamMember[]
      id: string
      leadId: string
      email: string
      magicLink: string
    }
  }
}

export type Address = {
  addressStreet1: string
  addressStreet2?: string
  city: string
  state: string
  zipCode: string
}
