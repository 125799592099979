import { QuizSteps, QuizStepType, StepId } from '@types'

export const quiz: QuizSteps[] = [
  {
    id: StepId.GENDER,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'Select Your Gender:',
    pretext:
      "<div class='mb-2'>Get your <span class='mc-callout-1'>personalized weight loss plan</span> by<br class='hidden md:block' /> completing this short quiz.</div>",
    options: [
      {
        name: 'Male',
        value: 'male',
      },
      {
        name: 'Female',
        value: 'female',
      },
    ],
  },
  {
    id: StepId.USER_GOALS,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'What is your primary goal?',
    options: [
      {
        name: 'Slim Down',
        value: 'slim_down',
      },
      {
        name: 'Gain Muscle',
        value: 'gain_muscle',
      },
      {
        name: 'Develop Healthy Habits',
        value: 'healthy_habits',
      },
    ],
  },
  {
    id: StepId.CONTENT_INTRO,
    type: QuizStepType.CONTENT,
    label: "<span class='text-3xl mb-2 font-normal'>We're here to help!</span>",
    hint: "<span class='callout-text'>Measured helps you achieve <strong>sustainable results</strong> through <span>nutrition</span>, <span>movement</span>, <span>mindfulness</span> and <span>medication</span>. All guided by your <em>personal health coach</em>.</span>",
    image: '/measured-approach.svg',
    image_alt: 'Measured Approach',
  },
  {
    id: StepId.HEIGHT_WEIGHT,
    type: QuizStepType.HEIGHT_WEIGHT,
    label: 'What is your height<br /> and weight?',
    hint: "We'll use this to calculate<br /> your Body Mass Index (BMI).",
    showHeight: true,
    showWeight: true,
    weightId: StepId.CURRENT_WEIGHT,
  },
  {
    id: StepId.WEIGHT_LOSS,
    type: QuizStepType.SLIDER,
    label: 'How much weight would<br /> you like to lose?',
    min: 0,
    maxSelector: 'selectMaxWeightLoss',
  },
  {
    id: StepId.STATE,
    type: QuizStepType.SELECT,
    label: 'Which state do you live in?',
    options: [
      {
        name: 'Alabama',
        value: 'AL',
      },
      {
        name: 'Alaska',
        value: 'AK',
      },
      {
        name: 'Arizona',
        value: 'AZ',
      },
      {
        name: 'Arkansas',
        value: 'AR',
      },
      {
        name: 'California',
        value: 'CA',
      },
      {
        name: 'Colorado',
        value: 'CO',
      },
      {
        name: 'Connecticut',
        value: 'CT',
      },
      {
        name: 'Delaware',
        value: 'DE',
      },
      {
        name: 'District of Columbia',
        value: 'DC',
      },
      {
        name: 'Florida',
        value: 'FL',
      },
      {
        name: 'Georgia',
        value: 'GA',
      },
      {
        name: 'Hawaii',
        value: 'HI',
      },
      {
        name: 'Idaho',
        value: 'ID',
      },
      {
        name: 'Illinois',
        value: 'IL',
      },
      {
        name: 'Indiana',
        value: 'IN',
      },
      {
        name: 'Iowa',
        value: 'IA',
      },
      {
        name: 'Kansas',
        value: 'KS',
      },
      {
        name: 'Kentucky',
        value: 'KY',
      },
      {
        name: 'Louisiana',
        value: 'LA',
      },
      {
        name: 'Maine',
        value: 'ME',
      },
      {
        name: 'Maryland',
        value: 'MD',
      },
      {
        name: 'Massachusetts',
        value: 'MA',
      },
      {
        name: 'Michigan',
        value: 'MI',
      },
      {
        name: 'Minnesota',
        value: 'MN',
      },
      {
        name: 'Mississippi',
        value: 'MS',
      },
      {
        name: 'Missouri',
        value: 'MO',
      },
      {
        name: 'Montana',
        value: 'MT',
      },
      {
        name: 'Nebraska',
        value: 'NE',
      },
      {
        name: 'Nevada',
        value: 'NV',
      },
      {
        name: 'New Hampshire',
        value: 'NH',
      },
      {
        name: 'New Jersey',
        value: 'NJ',
      },
      {
        name: 'New Mexico',
        value: 'NM',
      },
      {
        name: 'New York',
        value: 'NY',
      },
      {
        name: 'North Carolina',
        value: 'NC',
      },
      {
        name: 'North Dakota',
        value: 'ND',
      },
      {
        name: 'Ohio',
        value: 'OH',
      },
      {
        name: 'Oklahoma',
        value: 'OK',
      },
      {
        name: 'Oregon',
        value: 'OR',
      },
      {
        name: 'Pennsylvania',
        value: 'PA',
      },
      {
        name: 'Rhode Island',
        value: 'RI',
      },
      {
        name: 'South Carolina',
        value: 'SC',
      },
      {
        name: 'South Dakota',
        value: 'SD',
      },
      {
        name: 'Tennessee',
        value: 'TN',
      },
      {
        name: 'Texas',
        value: 'TX',
      },
      {
        name: 'Utah',
        value: 'UT',
      },
      {
        name: 'Vermont',
        value: 'VT',
      },
      {
        name: 'Virginia',
        value: 'VA',
      },
      {
        name: 'Washington',
        value: 'WA',
      },
      {
        name: 'West Virginia',
        value: 'WV',
      },
      {
        name: 'Wisconsin',
        value: 'WI',
      },
      {
        name: 'Wyoming',
        value: 'WY',
      },
    ],
  },
  {
    id: StepId.CONDITIONS,
    type: QuizStepType.MULTIPLE_CHOICE,
    label: 'Which of the following<br /> apply to you?',
    hint: 'This information is important to know if we can help you (please select all that apply)',
    compact: true,
    options: [
      {
        name: 'Pregnant or currently breastfeeding',
        value: 'pregnancy',
      },
      {
        name: 'Cancer treatment within the past 3 years',
        value: 'cancer',
      },
      {
        name: 'Active drug or alcohol abuse',
        value: 'drug-use',
      },
      {
        name: 'History of eating disorder: anorexia or bulimia nervosa',
        value: 'eating-disorder',
      },
      {
        name: 'Bariatric surgery or gastric sleeve',
        value: 'weight-loss-surgery',
      },
      {
        name: 'Serious mental health conditions',
        value: 'mental',
      },
      {
        name: 'Moderate or severe kidney disease or kidney transplant',
        value: 'kidney',
      },
      {
        name: 'Active hepatitis or liver disease',
        value: 'hepatitis',
      },
      {
        name: 'Daily use of steroid medications (e.g. prednisone)',
        value: 'steroid',
      },
      {
        name: 'Insulin to control blood sugars',
        value: 'insulin',
      },
      {
        name: 'Heart attack, stroke, or any heart conditions that limit your daily activities within the last six months',
        value: 'heart',
      },
      {
        name: 'None of these',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.BIRTHDAY,
    type: QuizStepType.BIRTHDAY,
    label: "What's your date of birth?",
    _comment:
      'Should have a content slide after this that either explains how we use this data or sets up the following questions.',
  },
  {
    id: StepId.RACE,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'What is your race?',
    options: [
      {
        name: 'Multi-racial',
        value: 'multiracial',
      },
      {
        name: 'Hispanic or Latino',
        value: 'hispanic',
      },
      {
        name: 'Black or African',
        value: 'black',
      },
      {
        name: 'White or Caucasian',
        value: 'white',
      },
      {
        name: 'Asian',
        value: 'asian',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
    ],
    _comment:
      'Good opportunity in next slide to tell them about how race can impact weight loss.',
  },
  {
    id: StepId.HEALTH_CONDITIONS,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: '(you can pick more than one)',
    compact: true,
    label: 'Do you have any of the below health conditions?',
    options: [
      {
        name: 'Diabetes',
        value: 'diabetes',
      },
      {
        name: 'Heart Disease',
        value: 'heart_disease',
      },
      {
        name: 'High Blood Pressure',
        value: 'high_blood_pressure',
      },
      {
        name: 'High Cholesterol',
        value: 'high_cholesterol',
      },
      {
        name: 'Sleep Apnea',
        value: 'sleep_apnea',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.LAST_IDEAL,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How long has it been since you were at your ideal weight?',
    options: [
      {
        name: 'Less than 1 year',
        value: 'less_than_one_year',
      },
      {
        name: '1-2 years',
        value: 'one_to_two_years',
      },
      {
        name: 'More than 3 years',
        value: 'more_than_three_years',
      },
      {
        name: 'Never',
        value: 'never',
      },
    ],
  },
  {
    id: StepId.PREFERRED_RATE,
    type: QuizStepType.SINGLE_CHOICE,
    label: "How fast do you want<br class='sm:hidden' /> to lose it?",
    options: [
      {
        name: '0.5 lbs a week',
        value: '0.5',
      },
      {
        name: '1 lbs a week',
        value: '1',
      },
      {
        name: '1.5 lbs a week',
        value: '1.5',
      },
      {
        name: '2 lbs a week',
        value: '2',
      },
    ],
    _comment:
      "Would be nice to ask if they're losing weight for a specific goal after this question.",
  },
  {
    id: StepId.ACTIVITY_LEVEL,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How physically active are you?',
    hint: 'Select an option from below.',
    options: [
      {
        name: 'Not very active',
        value: 'low',
      },
      {
        name: 'Exercise 1-2 times per week',
        value: 'medium',
      },
      {
        name: 'Exercise 3-5 times per week',
        value: 'high',
      },
      {
        name: 'Exercise 6+ times per week',
        value: 'very_high',
      },
    ],
  },
  {
    id: StepId.TYPICAL_DAY,
    type: QuizStepType.SINGLE_CHOICE,
    label: "What's your typical day like?",
    hint: 'Getting fit later in life requires a more personalized approach depending on your current lifestyle.',
    options: [
      {
        name: 'At the office',
        value: 'works_at_office',
      },
      {
        name: 'Daily long walks',
        value: 'takes_long_walks',
      },
      {
        name: 'Physical work',
        value: 'does_physical_work',
      },
      {
        name: 'Mostly at home',
        value: 'stay_at_home',
      },
    ],
  },
  {
    id: StepId.HABITS,
    type: QuizStepType.MULTIPLE_CHOICE,
    label: 'What are your habits?',
    hint: "(pick as many as you'd like)",
    options: [
      {
        name: 'I eat late at night',
        value: 'eat_late_at_night',
      },
      {
        name: "I don't get enough sleep",
        value: 'not_enough_sleep',
      },
      {
        name: "I can't give up sweets",
        value: 'too_many_sweets',
      },
      {
        name: 'I love soft drinks',
        value: 'soft_drinks',
      },
      {
        name: 'I eat too much salt',
        value: 'too_much_salt',
      },
      {
        name: 'None of above',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.OTHER_DIETS,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: "(pick as many as you'd like)",
    compact: true,
    label: 'Have you tried any of the following diets before?',
    options: [
      {
        name: 'Weight Watchers',
        value: 'weight_watchers',
      },
      {
        name: 'Noom',
        value: 'noom',
      },
      {
        name: 'Keto',
        value: 'keto',
      },
      {
        name: 'Intermittent Fasting',
        value: 'intermittent_fasting',
      },
      {
        name: 'Low Carb',
        value: 'low_carb',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.CONTENT_PERSONALIZED,
    type: QuizStepType.CONTENT,
    pretext: "<em class='text-3xl block mb-6'>Nutrition is personal.</em>",
    label:
      "<div class='font-normal mb-4'>There is no one size fits all, so we'll create a plan that <strong>works for you.</strong></div>",
    content:
      'Our team of nutritionists will create a perfect plan for you that fits your busy lifestyle, health goals and dietary preferences.',
  },
  {
    id: StepId.STRUGGLE,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: "(pick as many as you'd like)",
    compact: true,
    label: 'What are your biggest struggles with losing weight?',
    options: [
      {
        name: 'Lack of motivation',
        value: 'lack_motivation',
      },
      {
        name: 'Tempted by cravings',
        value: 'cravings',
      },
      {
        name: 'Stress eating',
        value: 'stress_eating',
      },
      {
        name: 'No time to cook',
        value: 'no_cookinng',
      },
      {
        name: 'Living with family',
        value: 'family',
      },
      {
        name: 'Health issues',
        value: 'health',
      },
      {
        name: 'Something else',
        value: 'other',
        showOther: true,
      },
    ],
  },
  {
    id: StepId.NUM_MEALS,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How many meals would you prefer to eat per day?',
    options: [
      {
        name: '2 meals',
        value: 2,
      },
      {
        name: '3 meals',
        value: 3,
      },
      {
        name: '4 meals (3 meals + 1 snack)',
        value: 4,
      },
      {
        name: '5 meals (3 meals + 2 snacks)',
        value: 5,
      },
    ],
  },
  {
    id: StepId.WATER,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How much water do you drink each day?',
    hint: '(Drinking water can help you reach your goals faster)',
    options: [
      {
        name: 'Less than 2 glasses (16 oz)',
        value: 1,
      },
      {
        name: '2-6 glasses (16 - 48oz)',
        value: 2,
      },
      {
        name: 'More than 6 glasses',
        value: 3,
      },
    ],
  },
  {
    id: StepId.FOODS,
    type: QuizStepType.MULTIPLE_CHOICE,
    negation: true,
    label: 'Do you like any of the following foods?',
    hint: "(pick as many as you'd like)",
    options: [
      {
        name: 'Eggs',
        value: 'eggs',
      },
      {
        name: 'Gluten / Flours',
        value: 'gluten',
      },
      {
        name: 'Nuts',
        value: 'nuts',
      },
      {
        name: 'Milk',
        value: 'milk',
      },
      {
        name: 'Cheese',
        value: 'cheese',
      },
    ],
  },
  {
    id: StepId.VEGETABLES,
    type: QuizStepType.MULTIPLE_CHOICE,
    negation: true,
    hint: "(pick as many as you'd like)",
    label: 'Do you like some of those vegetables?',
    options: [
      {
        name: 'Mushrooms',
        value: 'mushrooms',
      },
      {
        name: 'Asparagus',
        value: 'asparagus',
      },
      {
        name: 'Broccoli',
        value: 'broccoli',
      },
      {
        name: 'Beets',
        value: 'beets',
      },
      {
        name: 'Cauliflower',
        value: 'cauliflower',
      },
      {
        name: 'Tomato',
        value: 'tomato',
      },
      {
        name: 'Avocado',
        value: 'avocado',
      },
    ],
  },
  {
    id: StepId.MEATS,
    type: QuizStepType.MULTIPLE_CHOICE,
    negation: true,
    hint: "(pick as many as you'd like)",
    label: 'Do you eat any meat?',
    options: [
      {
        name: 'Fish',
        value: 'fish',
      },
      {
        name: 'Beef',
        value: 'beef',
      },
      {
        name: 'Chicken',
        value: 'chicken',
      },
      {
        name: 'Pork',
        value: 'pork',
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.FRUITS,
    type: QuizStepType.MULTIPLE_CHOICE,
    negation: true,
    hint: "(pick as many as you'd like)",
    label: 'Which fruits do you enjoy?',
    options: [
      {
        name: 'Apple',
        value: 'apple',
      },
      {
        name: 'Banana',
        value: 'banana',
      },
      {
        name: 'Grapes',
        value: 'grapes',
      },
      {
        name: 'Strawberry',
        value: 'strawberry',
      },
      {
        name: 'Raspberry',
        value: 'raspberry',
      },
      {
        name: 'Blueberries',
        value: 'blueberries',
      },
    ],
  },
  {
    id: StepId.ALLERGIES,
    type: QuizStepType.MULTIPLE_CHOICE,
    label: 'Do you have any food allergies?',
    options: [
      {
        name: 'Peanuts',
        value: 'peanuts',
      },
      {
        name: 'Gluten / Wheat',
        value: 'gluten',
      },
      {
        name: 'Dairy',
        value: 'dairy',
      },
      {
        name: 'Shellfish',
        value: 'shellfish',
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.CONTENT_SET_POINT,
    type: QuizStepType.CONTENT,
    image: '/warranty.svg',
    label: 'The Measured Guarantee',
    content:
      "<span class='text-xl'>We stand by our product and our coaches -- if you are not 100% satisfied with Measured within the first month of use, we will give your money back - no questions asked.</span>",
  },
]
