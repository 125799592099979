import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from 'redux/hooks';
import { selectIsEligible } from 'redux/eligiblity';

type EligibilityGuardProps = {
  children: React.ReactNode
}
const EligibilityGuard: React.FunctionComponent<EligibilityGuardProps> = ({ children }) => {
  const router = useRouter();
  const isEligible = useAppSelector(selectIsEligible)
  const [authorized, setAuthorized] = useState(true);

  const eligiblityCheck = useCallback((url: string) => {
    const path = url.split('?')[0];

    if (!isEligible && path.includes("/quiz")) {
      setAuthorized(true);
      router.push({
        pathname: '/ineligible',
      });
    } else {
      setAuthorized(true);
    }
  }, [router, isEligible])

  useEffect(() => {
    // on initial load - run auth check 
    eligiblityCheck(router.asPath);

    const hideContent = () => setAuthorized(false);
    router.events.on('routeChangeStart', hideContent);

    // on route change complete - run auth check 
    router.events.on('routeChangeComplete', eligiblityCheck)

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', hideContent);
      router.events.off('routeChangeComplete', eligiblityCheck);
    }
  }, [router.asPath, eligiblityCheck, router.events]);

  return <>{authorized && children}</>;
}

export default EligibilityGuard;