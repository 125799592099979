import { FunctionComponent, useEffect, useState } from 'react'
import Logo from '../public/m-wordmark.svg'
import BackIcon from '../public/icons/back.svg'
import Help from '../public/icons/help.svg'
import { useSelector } from 'react-redux'
import { selectPreviousStep } from 'redux/quiz/steps'
import { useRouter } from 'next/router'
import Progress from './Progress'
import Modal from './Modal'

interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
  const previousStep = useSelector(selectPreviousStep)
  const [showModal, setShowModal] = useState(false)
  const router = useRouter()

  const toggleHelpModal = () => {
    setShowModal(!showModal)
  }

  const goToPrevStep = () => {
    // if current URL includes your-plan, then go back using window.history.back
    if (router.asPath.includes('your-plan') || router.asPath.includes('checkout')) {
      window.history.back()
    } else {
      if (previousStep) {
        router.push(`/quiz?step=${previousStep}`)
      } else {
        window.history.back()
      }
    }
  }

  return (
    <>
      <Modal
        showModal={showModal}
        toggleModal={toggleHelpModal}
        modalClass="modal-help"
        modalTitle="Need Help?"
      >
        <div className="mx-auto">
          <div className="p-4">
            <p className="font-light mt-2 mb-5 text-lg">
              We&apos;re here to answer any questions you may have.
            </p>
            <h5 className="text-lg md:text-base">Access Your Plan:</h5>
            <p className="font-light mb-3">
              You can get your plan anytime by{' '}
              <a
                href="https://app.trymeasured.com/"
                className="text-brand-primary-4-darker underline"
              >
                clicking here
              </a>
              .
            </p>
            <h5 className="text-lg md:text-base">Other Questions:</h5>
            <p className="font-light mb-10">
              Feel free to send us an email at{' '}
              <a
                href="mailto:support@trymeasured.com"
                className="text-brand-primary-4-darker underline"
              >
                support@trymeasured.com
              </a>
              .
            </p>
            <button
              onClick={toggleHelpModal}
              className="bg-brand-primary-4-darker hover:bg-brand-primary-4-lighter text-white rounded px-x py-2 block font-semibold w-full"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Progress showProgress={true} />
      <div className="flex items-center justify-between w-full mx-auto px-4 pt-6 pb-2 h-16 z-10">
        <div className="w-1/4">
          {!router.pathname.includes('/order/') && (
            <BackIcon
              className="h-10 w-10 md:w-12 relative fill-current text-gray-800 opacity-30 back-nav cursor-pointer"
              onClick={() => goToPrevStep()}
            />
          )}
        </div>
        <div className={'w-1/2 flex justify-center items-center'}>
          <div className="relative">
            <Logo className="fill-current text-gray-800 w-32" />
          </div>
        </div>
        <div className="w-1/4 text-right">
          <div className="need-help-link">
            <span
              className="cursor-pointer hidden md:block"
              onClick={toggleHelpModal}
            >
              Need help?
            </span>
            <Help
              className="md:hidden float-right scale-125 fill-current text-brand-primary-4-default"
              onClick={toggleHelpModal}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
