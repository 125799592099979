import Actions from "@components/quiz/actions";
import { BirthdayStep, StepComponentProps } from "@types";
import { useState, useMemo, useEffect } from "react";
import Day from "./day";
import Month from "./month";
import Year from "./year";

type Props = StepComponentProps<BirthdayStep, { month: number, year: number, day: number } | null>

const Birthday: React.FC<Props> = (props: Props) => {
  const { value } = props;
  const [day, setDay] = useState<number | null>(value ? value.day : null)
  const [month, setMonth] = useState<number | null>(value ? value.month : null)
  const [year, setYear] = useState<number | null>(value ? value.year : null)

  useEffect(() => {
    if (value) {
      setDay(value.day);
      setMonth(value.month);
      setYear(value.year)
    }
  }, [value])

  const result = useMemo(() => {
    if (year && month && day) {
      return {
        year, month, day
      }
    }

    return null
  }, [day, month, year])

  const nextHandler = () => {
    props.onChange(props.step.id, result);
  }

  return (
    <fieldset>
      {props.step.hint && <legend className="block text-sm font-medium text-gray-700">{props.step.hint}</legend>}
      <div className="mt-1 shadow-sm space-x-1 flex flex-row">
        <Month value={month} onChange={(value: number) => setMonth(value)} />
        <Day month={month} year={year} value={day} onChange={(value: number) => setDay(value)} />
        <Year value={year} onChange={(value: number) => setYear(value)} />
      </div>
      <Actions onNext={nextHandler} nextEnabled={!!result} isLastStep={props.isLastStep} previousStep={props.previousStep} nextStep={props.nextStep} />
    </fieldset >
  )
}

export default Birthday
