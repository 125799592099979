/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import Image from "next/image";
import { StepComponentProps, ContentQuizStep } from "@types";
import markdownToHtml from "@utils/markdownToHtml";
import Actions from "@components/quiz/actions";

type ContentProps = StepComponentProps<ContentQuizStep, undefined>
interface ContentImageProps {
  imageUrl: string
  caption: string
}

const Content: React.FC<ContentProps> = (props: ContentProps) => {
  const { step } = props;
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    (async function convertToMarkdown() {
      const content = await markdownToHtml(step.content || "");
      setContent(content);
    })();
  }, [step])

  const ContentImage = ({ imageUrl, caption }: ContentImageProps) => {
    if (imageUrl) {
      return (
        <div className="my-6 relative">
          <img src={imageUrl} alt={caption} className="mx-auto" />
        </div>
      )
    } else {
      return null;
    }
  }

  return <>
    <ContentImage imageUrl={step.image} caption={step.image_alt} />
    <p className="text-center" dangerouslySetInnerHTML={{ __html: content }} />
    <Actions
      nextEnabled={true}
      previousStep={props.previousStep}
      nextStep={props.nextStep}
      isLastStep={props.isLastStep}
    />
  </>
}

export default Content;
