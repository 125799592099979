export function convertHeightFromMetricToImperial(heightInCm: number | null): {
  inches: number;
  feet: number;
};
export function convertHeightFromMetricToImperial(heightInCm: null): {
  inches: null;
  feet: null;
};
export function convertHeightFromMetricToImperial(heightInCm: number | null): {
  inches: number | null;
  feet: number | null;
} {
  if (!heightInCm) {
    return { inches: null, feet: null };
  }

  let inches: number;
  let feet: number;

  inches = heightInCm * 0.393700787;
  feet = Math.floor(inches / 12);
  inches %= 12;
  return { inches, feet };
}

// return cm
export const convertHeightFromImperialToMetric = (
  feet: number | null,
  inches: number | null
): number | null => {
  if (!inches || !feet) {
    return null;
  }

  const totalInches = inches + feet * 12;
  return totalInches * 2.54;
};

export function convertWeightFromMetricToImperial(value: number): number;
export function convertWeightFromMetricToImperial(value: null): null;
export function convertWeightFromMetricToImperial(
  value: number | null
): number | null;
export function convertWeightFromMetricToImperial(
  value: number | null
): number | null {
  return value ? Math.round(value * 2.20462) : null;
}

export function convertWeightFromImperialToMetric(value: number): number;
export function convertWeightFromImperialToMetric(value: null): null;
export function convertWeightFromImperialToMetric(
  value: number | null
): number | null;
export function convertWeightFromImperialToMetric(
  value: number | null
): number | null {
  return value ? value / 2.20462 : null;
}
