import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EligiblityReason, Gender, Macros, Units } from '../../@types'
import { selectAnswers } from '../quiz'
import { RootState } from '../store'

// Define a type for the slice state
type EligiblityState = {
  age: {
    max: number
    min: number
  }
  condition: {
    exclude: Array<string>
  }
  healthCondition: {
    exclude: Array<string>
  }
}

// Define the initial state using that type
const initialState: EligiblityState = {
  age: {
    max: 100,
    min: 21,
  },
  condition: {
    exclude: ['pregnancy', 'cancer', 'drug-use', 'heart', 'eating-disorder'],
  },
  healthCondition: {
    exclude: ['heart_disease'],
  },
}

export const eligiblitySlice = createSlice({
  name: 'eligiblity',
  initialState,
  reducers: {},
})

const isEligibleAge = (state: RootState): boolean => {
  const age = selectAnswers(state).age
  if (typeof age === 'number') {
    const { age: ageLimits } = state.eligiblity as EligiblityState
    return age >= ageLimits.min && age <= ageLimits.max
  }
  return true
}

function intersect<T>(a: Array<T>, b: Array<T>): Array<T> {
  const setA = new Set<T>(a)
  const setB = new Set<T>(b)
  const intersection = new Set([...setA].filter(x => setB.has(x)))
  return Array.from(intersection)
}

const isEligibleCondition = (state: RootState): boolean => {
  const conditions = selectAnswers(state).conditions
  if (conditions && state.eligiblity?.exclude) {
    const { condition: conditionRestrictions } =
      state.eligiblity as EligiblityState

    return intersect(conditions, conditionRestrictions.exclude).length === 0
  }
  return true
}

const isEligibleHealthCondition = (state: RootState): boolean => {
  const conditions = selectAnswers(state).health_conditions
  if (conditions && state.eligiblity?.exclude) {
    const { healthCondition: conditionRestrictions } =
      state.eligiblity as EligiblityState
    return intersect(conditions, conditionRestrictions.exclude).length === 0
  }
  return true
}

export const selectIsEligible = (state: RootState): boolean => {
  return (
    isEligibleAge(state) &&
    isEligibleCondition(state) &&
    isEligibleHealthCondition(state)
  )
}

export const selectIneligiblityReason = (
  state: RootState,
): EligiblityReason | null => {
  if (!isEligibleAge(state)) {
    return EligiblityReason.AGE
  }
  if (!isEligibleCondition(state) || !isEligibleHealthCondition(state)) {
    return EligiblityReason.CONDITION
  }
  return null
}

export default eligiblitySlice.reducer
