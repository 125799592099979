import {
  convertWeightFromMetricToImperial,
  convertWeightFromImperialToMetric,
} from "@utils/convert-units"
import { useState, useEffect } from "react";

interface WeightProps {
  value: number | null;
  onChange: (height: number | null) => void
}

const Weight: React.FunctionComponent<WeightProps> = ({ value, onChange }) => {
  const lbs = convertWeightFromMetricToImperial(value)
  const [lbsValue, setLbsValue] = useState(lbs)

  useEffect(() => {
    setLbsValue(lbs)
  }, [lbs, setLbsValue])

  const lbsChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLbs = parseInt(e.target.value)
    setLbsValue(newLbs)
    onChange(convertWeightFromImperialToMetric(newLbs))
  }

  return (
    <div className="flex space-x-10 mb-10 items-center w-full md:w-9/12 lg:w-1/2 mx-auto" style={{ maxWidth: 200 }}>
      <div className="flex border-b-4 text-2xl text-input-field bg-transparent justify-between w-full">
        <input id="weight-input" onChange={lbsChangeHandler} value={lbsValue || ""} type="number" className="weight hw-input" min="50" max="800" placeholder="Enter Weight" data-quiz--height-weight-target="weight" />
        <span>lbs.</span>
      </div>
      {false && <div className=" text-red-500 text-xs mt-1 ml-1">
        Invalid value !
      </div>}
    </div>
  );
}

export default Weight;
