import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import { useAppSelector } from '../../redux/hooks'
import {
  selectEmail,
  selectLeadId,
  selectName,
  selectTrack,
  selectUid,
} from '../../redux/user'

interface IUserTraits {
  [propName: string]: string | number | boolean
}

const DatdogRum = ({ setLoadedScript }: any) => {
  const uid = useAppSelector(selectUid)
  const id = useAppSelector(selectUid)
  const email = useAppSelector(selectEmail)
  const name = useAppSelector(selectName)
  const leadId = useAppSelector(selectLeadId)
  const track = useAppSelector(selectTrack)

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN) {
      datadogRum.init({
        applicationId: '47c1c174-2fb0-4147-bb35-57482ebff6e4',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN,
        site: 'datadoghq.com',
        service: 'measured-quiz',
        env: process.env.VERCEL_ENV,
        sampleRate: 100,
        premiumSampleRate: 100,
        version: process.env.NEXT_PUBLIC_VERSION,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      })
      datadogRum.startSessionReplayRecording()
      datadogRum.setUser(buildUser())
      setLoadedScript('datadogRum')
    }
  }, [])

  const buildUser = (): IUserTraits => {
    const traits: IUserTraits = {
      id: uid,
    }
    const setTraitAttribute = (
      name: string,
      value: string | number | boolean | null,
    ) => {
      if (value) {
        traits[name] = value
      }
    }
    setTraitAttribute('userId', id)
    setTraitAttribute('name', name)
    setTraitAttribute('email', email)
    setTraitAttribute('leadId', leadId)
    setTraitAttribute('track', track)

    return traits
  }

  return null
}
export default DatdogRum
