import { useAppSelector } from '../redux/hooks'
import { selectEmail } from '../redux/user'
import { useEffect, ReactNode, useState } from 'react'
import { useRouter } from 'next/router'
import { FacebookPixel } from '@components/Scripts/FacebookPixel'
import { MixpanelTracker } from '@components/Scripts/MixpanelTracker'
import GoogleAnalytics from '@components/Scripts/GoogleAnalytics'
import HelloBar from '@components/Scripts/HelloBar'
import Outbrain from '@components/Scripts/Outbrain'
import Criteo, { criteoHomepageEvent } from '@components/Scripts/Criteo'
import DatadogRum from '@components/Scripts/DatadogRum'

const scripts = {
  facebookPixel: false,
  mixpanel: false,
  googleAnalytics: false,
  helloBar: false,
  outbrain: false,
  criteo: false,
  datadogRum: false,
}

// This is because we use the same env variables across subdomains
const helloBarIds: any = {
  'localhost': '8aa86db97237e4ed4e2bc8a36c2d477478381bc5',
  'survey': '8aa86db97237e4ed4e2bc8a36c2d477478381bc5',
  '8am': '3baadcd6f5683ccf916aee1cac352fc1bbaf4474',
  'pt1': '40c15330a227c0a0eacdc44d73f12effc212ec65',
  'pt2': '08169c1d4e3326ba1c62f4b114e768cfee818c0a',
}

export default function Tracking({ children }: { children: ReactNode }) {
  const router = useRouter()
  const email = useAppSelector(selectEmail)
  const [loaded, setLoaded] = useState(scripts)
  const [firedInitialEvent, setFiredInitialEvent] = useState(scripts)
  const [hellobarId, setHellobarId] = useState('')

  const setLoadedScript = (name: string) => {
    // console.log('loaded', name)
    setLoaded({ ...loaded, [name]: true })
  }

  const registerHomepageEvent = (name: string) => {
    // console.log('fired initial event', name)
    setFiredInitialEvent({ ...firedInitialEvent, [name]: true })
  }

  const isHomepage = (url: string) => {
    if (url === '/' ||
      url === '/quiz?step=1' ||
      url === '/quiz/start-female' ||
      url === '/quiz/start-male'
    ) {
      return true
    } else {
      return false
    }
  }

  // Track client-side page views.
  const handleRouteChange = (url: string) => {
    if (email) {
      // TODO - Implement Customer.io tracking
    }

    // Events that only need firing once per session
    if (isHomepage(url)) {
      if (loaded.facebookPixel && !firedInitialEvent.facebookPixel) {
        window?.ReactPixel?.track('ViewContent')
        registerHomepageEvent('facebookPixel')
      }

      if (loaded.criteo && !firedInitialEvent.criteo) {
        criteoHomepageEvent()
        registerHomepageEvent('criteo')
      }
    }
  }

  // On initial page load.
  useEffect(() => {
    handleRouteChange(router?.asPath || '')

    if (typeof window !== 'undefined') {
      // console.log('window loaded')
      // get subdomain from url
      const subdomain = window.location.hostname.split('.')[0]
      console.log('subdomain', subdomain)
      setHellobarId(helloBarIds[subdomain || 'survey'] || helloBarIds['survey'])
    }
  }, [loaded])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [email, router.events, router.asPath])

  return <>
    {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && <GoogleAnalytics setLoadedScript={setLoadedScript} />}
    {process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID && <FacebookPixel setLoadedScript={setLoadedScript} />}
    {process.env.NEXT_PUBLIC_MIXPANEL_API_KEY && loaded?.mixpanel && <MixpanelTracker />}
    {process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN && <DatadogRum setLoadedScript={setLoadedScript} />}
    {process.env.NEXT_PUBLIC_OUTBRAIN_ID && <Outbrain setLoadedScript={setLoadedScript} />}
    {process.env.NEXT_PUBLIC_CRITEO_ID && <Criteo setLoadedScript={setLoadedScript} />}
    {hellobarId && !loaded.helloBar && <HelloBar hellobarId={hellobarId} setLoadedScript={setLoadedScript} />}
    {children}
  </>
}
