import { useEffect, useState } from 'react'
import Script from "next/script";

const HelloBar = ({ hellobarId, setLoadedScript }: any) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  })

  return ((!loading && hellobarId) ?
    <Script src={`https://my.hellobar.com/${hellobarId}.js`} onLoad={() => { setLoadedScript('helloBar') }} /> : null)
}

export default HelloBar;
