import { Router, useRouter } from 'next/router'
import React from 'react'

export const FacebookPixel = ({ setLoadedScript }: any) => {
  {
    const router = useRouter()

    React.useEffect(() => {
      import('@bettercart/react-facebook-pixel')
        .then(x => x.default)
        .then(ReactPixel => {
          ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string)
          ReactPixel.pageView()

          Router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView()
            console.log('Page View')
          })

          setLoadedScript('facebookPixel')
          window.ReactPixel = ReactPixel
        })
    }, [])
    return null
  }
}
