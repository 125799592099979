interface YearProps {
  value: number | null;
  onChange: (value: number) => void
}

const Year: React.FC<YearProps> = ({ value, onChange }) => {
  const years: Array<number> = []
  const currentYear = new Date().getFullYear()
  for (var i = currentYear; i > currentYear - 100; i--) {
    years.push(i);
  }

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(parseInt(event.target.value));
  }

  return <div>
    <label htmlFor="year" className="sr-only">
      Year
    </label>
    <select
      id="year"
      name="year"
      autoComplete="Year"
      onChange={changeHandler}
      value={value?.toString()}
      className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full focus:z-10 text-lg border-gray-300"
    >
      <option disabled={!!value}>Year</option>
      {years.map((year) => <option value={year} key={year}>{year}</option>)}
    </select>
  </div>
}

export default Year;
