import { ReactNode, useEffect } from 'react'
import { useAppDispatch } from '../redux/hooks'
import { setTrack } from '../redux/user'
import { StringParam, useQueryParam } from 'use-query-params'
import { Track } from '@types'

export default function OverrideTrack({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  const [track] = useQueryParam('track', StringParam)

  useEffect(() => {
    if (track && (track === Track.MEDICAL || track === Track.BEHAVIORAL)) {
      dispatch(setTrack(track))
    }
  }, [track])

  return <>{children}</>
}
