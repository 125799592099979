import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Define a type for the slice state
type ScreenState = {
  loading: boolean;
};

// Define the initial state using that type
const initialState: ScreenState = {
  loading: false,
};

export const screenSlice = createSlice({
  name: "screen",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const selectScreenLoading = (state: RootState): boolean =>
  state.screen.loading;

export const { setLoading } = screenSlice.actions;

export default screenSlice.reducer;
