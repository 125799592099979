import { combineReducers } from '@reduxjs/toolkit'
import calculateAge from '@utils/calculateAge'
import { Gender, QuizStepType, StepId, Units } from '../../@types'
import { RootState } from '../store'
import formStateReducer, {
  setStepValue,
  selectStepValue,
  setQuizAnswers,
  reset,
} from './formState'
import stepsReducer from './steps'
import {
  convertWeightFromImperialToMetric,
  convertHeightFromMetricToImperial,
} from '@utils/convert-units'

interface Answers {
  name: string
  current_weight: number
  height: number
  cm: number
  goal_weight: number
  progressAmount: number
  gender: Gender
  age: number | undefined
  conditions: Array<string>
  health_conditions: string[]
  state: string
}

const selectAnswers = (state: RootState): Answers => {
  const { formState } = state.quiz

  let answers = {
    ...formState,
    progressAmount: 10,
    health_goal: 'lose_weight',
  }
  if (formState[StepId.HEIGHT_WEIGHT]) {
    answers = {
      ...answers,
      height: formState[StepId.HEIGHT_WEIGHT]['height'],
      cm: formState[StepId.HEIGHT_WEIGHT]['height'],
      current_weight: formState[StepId.HEIGHT_WEIGHT]['weight'],
    }
  }

  if (formState['goal_weight']) {
    answers = {
      ...answers,
      goal_weight: formState['goal_weight']['weight'],
    }
  }

  if (formState[StepId.NAME]) {
    answers = {
      ...answers,
      name: formState[StepId.NAME],
    }
  }

  if (formState[StepId.WEIGHT_LOSS] && answers[StepId.CURRENT_WEIGHT]) {
    const goal_weight =
      answers[StepId.CURRENT_WEIGHT] -
      convertWeightFromImperialToMetric(formState[StepId.WEIGHT_LOSS] as number)
    answers = {
      ...answers,
      goal_weight: goal_weight,
    }
  }

  if (formState[QuizStepType.BIRTHDAY]) {
    answers = {
      ...answers,
      age: calculateAge(
        formState[QuizStepType.BIRTHDAY]['year'],
        formState[QuizStepType.BIRTHDAY]['month'],
        formState[QuizStepType.BIRTHDAY]['day'],
      ),
    }
  }

  return answers
}

const selectLocation = (state: RootState): string => {
  const { formState } = state.quiz

  return formState[StepId.STATE]
}

export function selectHeight(state: RootState, units: 'metric'): number
export function selectHeight(
  state: RootState,
  units: 'imperial',
): { inches: number; feet: number }
export function selectHeight(
  state: RootState,
  units: Units,
): number | { inches: number; feet: number } {
  const heightInMeters = selectAnswers(state).height
  if (units === 'imperial') {
    return convertHeightFromMetricToImperial(selectAnswers(state).height)
  }
  return heightInMeters
}

export {
  setStepValue,
  selectStepValue,
  selectAnswers,
  selectLocation,
  setQuizAnswers,
  reset,
}

export default combineReducers({
  formState: formStateReducer,
  steps: stepsReducer,
})
