import { MultipleChoiceQuizStep, StepComponentProps } from '../../../@types'
import React, { useState, useMemo, useEffect } from 'react'
import { Set } from 'immutable'
import Actions from '@components/quiz/actions'
import { mapSelectOptions } from '../../SelectOption'
import Icon from '../../Icon'

type Props = StepComponentProps<MultipleChoiceQuizStep, Array<string>>

const OTHER_PREFIX = 'other:'

export default function MultipleChoice(
  props: Props,
): React.ReactElement<Props> {
  const { value, onChange, step } = props
  const [values, setValues] = useState(Set<string>([]))
  const [otherValue, setOtherValue] = useState<string | undefined>()
  const showOtherField = useMemo(() => values.includes('other'), [values])

  const nextEnabled = useMemo(
    () =>
      showOtherField ? !!otherValue && otherValue?.length > 0 : values.size > 0,
    [values, otherValue],
  )

  useEffect(() => {
    if (Array.isArray(value)) {
      setValues(Set<string>(value.filter(s => !s.startsWith(OTHER_PREFIX))))
      setOtherValue(
        value.find(s => s.startsWith(OTHER_PREFIX))?.replace(OTHER_PREFIX, ''),
      )
    }
  }, [value])

  const handleInputChange = (value: string, selected: boolean) => {
    let newValues
    if (selected) {
      newValues = value === 'none' ? values.clear() : values.delete('none')
      newValues = newValues.add(value)
    } else {
      newValues = values.delete(value)
    }
    setValues(newValues)
  }

  const nextHandler = () => {
    const newValue =
      otherValue && otherValue?.length > 0
        ? values.add(`other:${otherValue}`)
        : values
    onChange(step.id, newValue.toArray())
  }

  const options = step.options.map((option, optionIdx) => ({
    value: option.value,
    content: (
      <div className="w-full">
        {option.icon && (
          <Icon width="40" height="40" className="mx-auto" name={option.icon} />
        )}
        <div className="">
          <p>{option.name}</p>
          {showOtherField && option.showOther && (
            <div className="mt-2">
              {
                <input
                  type="text"
                  className="border-1 border-gray-400 rounded w-full"
                  id="other-field-input"
                  min="50"
                  max="800"
                  onChange={e => setOtherValue(e.currentTarget.value)}
                  placeholder="Type it here"
                  value={otherValue}
                />
              }
            </div>
          )}
        </div>
      </div>
    ),
    selected: values.includes(option.value),
    onClick: handleInputChange,
  }))

  return (
    <>
      <div className="flex flex-wrap min-w-full max-w-2xl -mx-2 items-center justify-center text-sm md:my-2">
        {step.columns &&
          mapSelectOptions({
            height: 'h-24 sm:h-32',
            width: 'w-1/2 md:w-1/3',
            spacing: 'm-1 sm:mx-3',
            padding: 'p-1 sm:p-3',
            options,
          })}
        {!step.columns &&
          mapSelectOptions({
            height: step.compact ? 'h-15' : 'h-30',
            width: 'w-full',
            spacing: step.compact ? 'm-1' : 'm-2',
            options,
          })}
      </div>

      <Actions
        onNext={nextHandler}
        nextEnabled={nextEnabled}
        isLastStep={props.isLastStep}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
      />
    </>
  )
}
