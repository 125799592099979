export default function calculateAge(
  year: number,
  month: number,
  day: number
): number {
  var today = new Date();
  var birthDate = new Date(year, month, day);
  var age = today.getFullYear() - year;
  var m = today.getMonth() - month;
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
