import { useEffect, useState } from 'react'
import CheckEmpty from '../public/icons/check-empty.svg'
import Check from '../public/icons/check.svg'

export const SelectOption = ({
  content,
  width,
  height,
  spacing,
  padding,
  value,
  onClick = () => {},
  selected = false,
}) => {
  const [isSelected, setIsSelected] = useState(selected)

  useEffect(() => {
    setIsSelected(selected)
  }, [selected])

  const clickSelect = ev => {
    ev.preventDefault()
    ev.stopPropagation()
    if (ev.target.id === 'other-field-input') {
      return
    }
    setIsSelected(!isSelected)
    onClick(value, !isSelected)
  }

  const wrapClasses = [
    'flex text-center items-stretch justify-center',
    width,
    height,
  ]
    .join(' ')
    .trim()

  const itemClasses = [
    isSelected ? 'selected' : '',
    'select-option-item',
    spacing,
    padding,
  ]
    .join(' ')
    .trim()

  return (
    <div className={wrapClasses}>
      <div
        className={itemClasses}
        id={`select-${value}`}
        onClick={clickSelect}
        data-value={value}
      >
        {isSelected ? (
          <Check className="fill-current text-brand-bright" />
        ) : (
          <CheckEmpty className="fill-current text-gray-900 opacity-30" />
        )}
        {content}
      </div>
    </div>
  )
}

export const mapSelectOptions = ({
  width = 'w-1/2',
  height = '',
  spacing = 'm-2',
  padding = 'p-4',
  options,
}) => {
  return options.map((option, i) => (
    <SelectOption
      key={i}
      width={width}
      height={height}
      spacing={spacing}
      padding={padding}
      {...option}
    />
  ))
}

export default SelectOption
