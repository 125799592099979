import { useEffect } from "react";
import TagManager from 'react-gtm-module';

const GoogleAnalytics = ({ setLoadedScript }: any) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
    }

    TagManager.initialize(tagManagerArgs);
    setLoadedScript('googleAnalytics')
  }, []);

  return null;
}

export default GoogleAnalytics;
