import React from "react";

interface DayProps {
  value: number | null;
  onChange: (value: number) => void
  month: number | null;
  year: number | null;
}

function daysInMonth(month: number | null, year: number | null): number {
  if (month && year) {
    return new Date(year, month, 0).getDate();
  }

  return 31;
}

const Day: React.FC<DayProps> = ({ month, year, value, onChange }) => {
  const possibleDays: Array<number> = []
  for (var i = 1; i <= daysInMonth(month, year); i++) {
    possibleDays.push(i);
  }

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(parseInt(event.target.value));
  }

  return (<div>
    <label htmlFor="day" className="sr-only">
      Day
    </label>
    <select
      id="day"
      name="day"
      autoComplete="Day"
      value={value?.toString()}
      onChange={changeHandler}
      className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full focus:z-10 text-lg border-gray-300"
    >
      <option disabled={!!value}>Day</option>
      {possibleDays.map(i => <option value={i} key={i}>{i}</option>)}
    </select>
  </div >);
}

export default Day;
