import React, { useState } from 'react'
import { QuizProps, QuizSteps } from '@types'
import Quiz from '@components/quiz'
import { NumberParam, useQueryParam } from 'use-query-params'
import { quiz as quizData } from '@data/quiz'

const QuizPage: React.FC<QuizProps> = props => {
  const [quiz, setQuiz] = useState<QuizSteps[]>(quizData)
  const [step] = useQueryParam('step', NumberParam)

  return (
    <Quiz
      step={quiz[(step || 1) - 1]}
      currentStep={step || 1}
      nextStep={(step || 1) < quiz.length ? (step || 1) + 1 : null}
      previousStep={(step || 1) - 1}
      isLastStep={step === quiz.length}
      stepsCount={quiz.length}
    />
  )
}

export default QuizPage
