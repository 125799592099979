import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Layout from '../layouts/Layout'
import store from '../redux/store'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import EligibilityGuard from '@components/EligiblityGuard'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Tracking from '@components/Tracking'
import { QueryParamProvider } from 'use-query-params'
import { NextAdapter } from 'next-query-params'
import OverrideTrack from '@components/OverrideTrack'

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter()

  const setBodyClass = (className: string) => {
    const body = window.document.querySelector('body')
    if (typeof window !== 'undefined' && body !== null) {
      body.className = className
    }
  }

  useEffect(() => {
    if (
      asPath.includes('quiz') ||
      asPath.includes('restore') ||
      asPath.includes('thanks')
    ) {
      setBodyClass('quiz')
    } else if (asPath.includes('results')) {
      setBodyClass('results')
    } else if (asPath.includes('order')) {
      setBodyClass('order')
    } else {
      setBodyClass('')
    }
  }, [asPath])

  return (
    <QueryParamProvider adapter={NextAdapter}>
      <Provider store={store}>
        <Head>
          <title>Measured - Modern Weight Loss</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png?original"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png?original"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png?original"
          />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff"></meta>
          <meta
            name="description"
            content="Measured uses modern medicine and personalized care plans to help you lose weight and keep it off."
          />
          <meta
            name="keywords"
            content="measured health,measured weight loss,lose weight,medical weight loss,glp-1,metformin,keto,low carb,prescription weight loss,semaglutide,wegovy,monjouro"
          />
        </Head>

        <div>
          <Tracking>
            <OverrideTrack>
              <Layout>
                <Sentry.ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
                  <EligibilityGuard>
                    <Component {...pageProps} />
                  </EligibilityGuard>
                </Sentry.ErrorBoundary>
              </Layout>
            </OverrideTrack>
          </Tracking>
        </div>
      </Provider>
    </QueryParamProvider>
  )
}

export default MyApp
